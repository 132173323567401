import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";


const ComingSoon = lazy(() => import("./pages/Home/Home"));



function AppContent() {

  return (
    <>

      <div className="appContainer">
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<ComingSoon />} />



          </Routes>
        </Suspense>
      </div>
      {/* {!isAuthRoute && <SubFooter />} */}
    </>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
